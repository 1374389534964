





























































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { EAvatarType } from '@/models'
import { getModule } from 'vuex-module-decorators'
import ModalError from '@/components/Modal/ModalError.vue'
import UserService from '@/services/UserService'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
import AuthService from '@/services/AuthService'
import Auth from '@/store/modules/Auth'
import i18n from '@/lang'

const AuthModule = getModule(Auth, store)

const UserModule = getModule(UserInfo, store)

@Component({
  components: {
    ModalError
  }
})
export default class ShowProfile extends Vue {
  private showProfile: boolean = true
  private eAvatarType: any = EAvatarType
  private avatar_type: EAvatarType = EAvatarType.DEFAULT
  public profile: any = {
    email_option: 1,
    avatar: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    self_introduction: ''
  }
  private errorTypeAndSize: boolean = false
  private newAvatar: any = ''
  private newAvatarFileName: string = String(
    this.$t('common.form.not_selected')
  )
  private newAvatarBlob: any = ''
  private avatarCropperSrc: string = ''
  private noAvatar = require('@/assets/images/avatar-default.png')
  private ehumbNailsDisplay = EhumbNailsDisplay
  private loading = false
  private loadingData: boolean = true

  private email_options: any[] = [
    {
      id: 1,
      text: this.$t('common.select.private'),
      value: 0
    },
    {
      id: 2,
      text: this.$t('common.select.public'),
      value: 1
    }
  ]

  created() {
    this.getUserProfile()
  }

  @Watch('avatar_type')
  watchAvatarType() {
    if (this.avatar_type === EAvatarType.NONE) {
      this.newAvatar = ''
      this.newAvatarBlob = ''
      this.errorTypeAndSize = false
      this.newAvatarFileName = ''
    } else {
      this.newAvatar = this.$store.state.userInfo.user.profile.avatar_small
      this.newAvatarFileName =
        store.state.userInfo.user.profile.avatar_file_name
    }
    this.$emit('handleUserProfile', {
      profile: this.profile,
      avatarType: this.avatar_type,
      newAvatar: this.newAvatarBlob
    })
  }

  async backToLogin() {
    var params = {
      token: sessionStorage.getItem('device_token')
    }
    this.$emit('confirmIgnore')
    this.$blockui.show()
    this.loading = true
    if (params.token) {
      setTimeout(() => {
        AuthService.resetTotalNotification(params)
          .then(async (response) => {
            if (response.status === 200) {
              console.log('response.message: ', response.data.message);
              
              await AuthService.logout(params)
                .then(res => {
                  AuthModule.SET_TOKEN_NULL()
                  i18n.locale = 'ja'
                  if (res.status === 200) {
                    this.$router.push({
                      name:
                        this.$store.state.auth.loginFromLoginByEmail === true
                          ? 'email-login'
                          : 'login'
                    })
                    sessionStorage.clear()
                    localStorage.clear()
                  }
                })
                .catch(err => {
                  if (err) {
                    this.$router.push({ name: 'home' })
                  }
                })
                .finally(() => this.$blockui.hide())
            }
        })
      }, 3000)
    } else {
      await AuthService.logout(params)
        .then(res => {
          AuthModule.SET_TOKEN_NULL()
          i18n.locale = 'ja'
          if (res.status === 200) {
            this.$router.push({
              name:
                this.$store.state.auth.loginFromLoginByEmail === true
                  ? 'email-login'
                  : 'login'
            })
            sessionStorage.clear()
            localStorage.clear()
          }
        })
        .catch(err => {
          if (err) {
            this.$router.push({ name: 'home' })
          }
        })
        .finally(() => this.$blockui.hide())
    }
  }

  getUserProfile() {
    UserService.getUserProfile()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_PROFILE(response.data)
          this.profile = Object.assign({}, store.state.userInfo.user.profile)
          this.newAvatarFileName =
            store.state.userInfo.user.profile.avatar_file_name
          if (!this.profile.avatar) {
            this.avatar_type = EAvatarType.NONE
          } else {
            this.avatar_type = EAvatarType.FILE_UPLOAD
          }

          setTimeout(() => {
            this.loadingData = false
          }, 1000)

          this.$emit('handleUserProfileCurrent', {
            profile: { ...this.profile },
            avatarType: this.avatar_type,
            newAvatar: this.newAvatarBlob
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleCropperImg() {
    const canvas = (this.$refs.avatarCropper as any).clip()
    canvas.toBlob((blob: Blob) => {
      this.newAvatar = URL.createObjectURL(blob)
      this.newAvatarBlob = blob
      this.$bvModal.hide('cropAvatar')
      this.$emit('handleUserProfile', {
        profile: this.profile,
        avatarType: this.avatar_type,
        newAvatar: this.newAvatarBlob
      })
    })
  }

  /**
   * Open avatar cropper
   */
  onAvatarChange(event: any) {
    let imageType = ['image/png', 'image/gif', 'image/jpeg']
    let files = event.target.files || event.dataTransfer.files
    if (event.target.files.length !== 1) return
    const blob = new Blob([files[0]])
    if (files[0].size > 26214400 || !imageType.includes(files[0].type)) {
      this.errorTypeAndSize = true
    } else {
      this.avatarCropperSrc = URL.createObjectURL(blob)
      this.newAvatarFileName = files[0].name
      this.$bvModal.show('cropAvatar')
    }
  }

  /**
   * Cancel avatar cropper
   */
  cancelCropper() {
    this.newAvatarFileName = this.$t('common.form.not_selected') as string
    this.$bvModal.hide('cropAvatar')
    this.newAvatarBlob = ''
    this.newAvatar = ''
  }

  updateProfile() {
    this.$blockui.show()
    this.errorTypeAndSize = false
    let formData = new FormData()
    formData.set('email_option', this.profile.email_option)
    if (this.$store.state.userInfo.user.profile.avatar !== this.newAvatar) {
      formData.set('avatar', this.newAvatarBlob)
      formData.set('avatar_file_name', this.newAvatarFileName)
    }
    formData.set('last_name', this.profile.last_name)
    formData.set('first_name', this.profile.first_name)
    formData.set('last_name_kana', this.profile.last_name_kana)
    formData.set('first_name_kana', this.profile.first_name_kana)
    formData.set(
      'self_introduction',
      this.profile.self_introduction ? this.profile.self_introduction : ''
    )
    UserService.updateUserProfile(formData)
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_PROFILE(response.data)
          if (!this.profile.first_name) {
            this.profile.first_name = ''
          }
          if (!this.profile.last_name) {
            this.profile.last_name = ''
          }
          let user_name = `${this.profile.first_name} ${this.profile.last_name}`
          UserModule.UPDATE_USER_NAME(user_name)
          this.$emit('changeProfile', true)
          this.$emit('handleUserProfileCurrent', {
            profile: this.profile,
            avatarType: this.avatar_type,
            newAvatar: this.newAvatarBlob
          })
        }
      })
      .catch(error => {
        this.getUserProfile()
        this.$emit('changeProfile', false)
      })
      .finally(() => this.$blockui.hide())
  }
}











































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { VeeValidateObserverRef } from '@/models/Common/Validation'
import UserService from '@/services/UserService'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'
import { getModule } from 'vuex-module-decorators'
//@ts-ignore
import _ from 'lodash'
const UserModule = getModule(UserInfo, store)

@Component
export default class ShowCompanyInfo extends Vue {
  $refs!: {
    observer: VeeValidateObserverRef
  }
  private showCompanyInfo: boolean = true
  public companyInfo: any = {
    company_info_display_option: true,
    company_name: '',
    department: '',
    job_title: '',
    zipcode: '',
    prefecture: '',
    city: '',
    other_address: '',
    building_name: '',
    phone: [''],
    url: ['']
  }

  postcodeSearch() {
    UserService.getZipCode(this.companyInfo.zipcode)
      .then(res => {
        if (res.status === 200) {
          this.companyInfo.prefecture = res.data.province_name
          this.companyInfo.city = res.data.district_name
          this.companyInfo.other_address = res.data.city_name
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  created() {
    this.getUserCompanyInfo()
  }

  getUserCompanyInfo() {
    UserService.getUserCompanyInfo()
      .then(res => {
        if (res.status === 200) {
          if (!_.isEmpty(res.data)) {
            UserModule.HANDLE_USER_COMPANY_INFO(res.data)
          }
          this.companyInfo = {
            ...store.state.userInfo.user.company_info,
            phone: [...store.state.userInfo.user.company_info.phone],
            url: [...store.state.userInfo.user.company_info.url]
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.$emit('handleUserCompanyInfo', this.companyInfo))
  }

  updateCompanyInfo() {
    this.$refs.observer.validate().then(isValidate => {
      if (!isValidate) {
        return
      } else {
        const new_company_info = {
          company_info_display_option: this.companyInfo
            .company_info_display_option,
          company_name: this.companyInfo.company_name,
          department: this.companyInfo.department,
          job_title: this.companyInfo.job_title,
          zipcode: this.companyInfo.zipcode,
          prefecture: this.companyInfo.prefecture,
          city: this.companyInfo.city,
          other_address: this.companyInfo.other_address,
          building_name: this.companyInfo.building_name,
          phone: this.companyInfo.phone,
          url: this.companyInfo.url
        }
        UserService.updateUserCompanyInfo(new_company_info)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              res.data.phone = JSON.parse(res.data.phone)
              res.data.url = JSON.parse(res.data.url)
              UserModule.HANDLE_USER_COMPANY_INFO(res.data)
              this.$emit('isCompanyInfoUpdated', true)
              this.$emit('handleUserCompanyInfo', this.companyInfo)
            }
          })
          .catch(error => {
            this.getUserCompanyInfo()
            this.$emit('isCompanyInfoUpdated', false)
          })
      }
    })
  }

  addPhoneNumberField() {
    if (this.companyInfo.phone.length < 10) {
      this.companyInfo.phone.push('')
    } else {
      return null
    }
  }

  addUrlField() {
    if (this.companyInfo.url.length < 10) {
      this.companyInfo.url.push('')
    } else {
      return null
    }
  }

  removePhoneNumberField(index: any) {
    this.companyInfo.phone.splice(index, 1)
  }

  removeUrlField(index: any) {
    this.companyInfo.url.splice(index, 1)
  }
}

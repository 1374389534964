


























import { Component, Vue } from 'vue-property-decorator'
import ShowProfile from './Components/ShowProfile.vue'
import ShowCompanyInfo from './Components/ShowCompanyInfo.vue'
import ShowPersonalInfo from './Components/ShowPersonalInfo.vue'
//@ts-ignore
import _ from 'lodash'
import AuthService from '@/services/AuthService'
import store from '@/store'
import Auth from '@/store/modules/Auth'
import { getModule } from 'vuex-module-decorators'
import i18n from '@/lang'
const AuthModule = getModule(Auth, store)

@Component({
  components: {
    ShowProfile,
    ShowCompanyInfo,
    ShowPersonalInfo
  }
})
export default class ChangeInfo extends Vue {
  private confirmChange: any
  private userProfile: any = {}
  private userProfileCurrent: any = {}
  private companyInfo: any = {}
  private personalIInfo: any = {}
  private confirmLeaveIgnore: boolean = false

  checkRouterBeforLeave() {
    const userProfileCheck =
      _.isEqual(this.userProfile.profile, this.userProfileCurrent.profile) &&
      this.userProfile.newAvatar === this.userProfileCurrent.newAvatar &&
      this.userProfile.avatarType === this.userProfileCurrent.avatarType

    const companyInfoStore = {
      ...this.$store.state.userInfo.user.company_info,
      phone: [...this.$store.state.userInfo.user.company_info.phone],
      url: [...this.$store.state.userInfo.user.company_info.url]
    }

    const conpanyInfoCheck = _.isEqual(this.companyInfo, companyInfoStore)

    const personalInfoStore = {
      ...this.$store.state.userInfo.user.personal_info,
      phone: [...this.$store.state.userInfo.user.personal_info.phone],
      url: [...this.$store.state.userInfo.user.personal_info.url]
    }

    const personalInfoCheck = _.isEqual(this.personalIInfo, personalInfoStore)

    return userProfileCheck && conpanyInfoCheck && personalInfoCheck
  }

  /**
   * beforeRouteLeave
   */
  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.confirmChange = next
      this.$bvModal.show('modal_confirm_unsave_delete')
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  changeProfile(isProfileUpdated: boolean) {
    if (isProfileUpdated) {
      this.$bvModal.show('modal-success')
    } else {
      this.$bvModal.show('modal-error')
    }
  }

  changeCompanyInfo(isCompanyInfoUpdated: boolean) {
    if (isCompanyInfoUpdated) {
      this.$bvModal.show('modal-success')
    } else {
      this.$bvModal.show('modal-error')
    }
  }

  changePersonalInfo(isPersonalInfoUpdated: boolean) {
    if (isPersonalInfoUpdated) {
      this.$bvModal.show('modal-success')
    } else {
      this.$bvModal.show('modal-error')
    }
  }

  handleUserProfile(profile: any) {
    this.userProfile = profile
  }

  handleUserProfileCurrent(profile: any) {
    this.userProfileCurrent = profile
  }

  handleUserPersonalInfo(personalInfo: any) {
    this.personalIInfo = personalInfo
  }

  handleUserCompanyInfo(companyInfo: any) {
    this.companyInfo = companyInfo
  }
}

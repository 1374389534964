









































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { VeeValidateObserverRef } from '@/models/Common/Validation'
import UserService from '@/services/UserService'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'
import { getModule } from 'vuex-module-decorators'
import moment from 'moment'
//@ts-ignore
import _ from 'lodash'
const UserModule = getModule(UserInfo, store)

@Component
export default class ShowPersonalInfo extends Vue {
  $refs!: {
    observer: VeeValidateObserverRef
  }
  private errorBirthdayMsg: boolean = false
  private checkBirthDate: boolean = false
  private showPersonalInfo: boolean = true
  public years: any[] = []
  public days: any[] = []
  public months: any[] = []
  public personalInfo: any = {
    gender: null,
    birthday_year: null,
    birthday_month: null,
    birthday_day: null,
    birthday_option: 0,
    nearest_station: '',
    zipcode: null,
    prefecture: '',
    prefecture_option: 0,
    city: '',
    other_address: '',
    building_name: '',
    phone: [''],
    url: ['']
  }

  public gender_options: any[] = [
    {
      id: 1,
      text: this.$t('common.select.not_set'),
      value: null
    },
    {
      id: 2,
      text: this.$t('setting.change_profile.female'),
      value: 0
    },
    {
      id: 3,
      text: this.$t('setting.change_profile.male'),
      value: 1
    }
  ]

  public birthday_options: any[] = [
    {
      id: 1,
      text: this.$t('common.select.private'),
      value: 0
    },
    {
      id: 2,
      text: this.$t('common.select.public'),
      value: 1
    }
  ]

  public prefecture_options: any[] = [
    {
      id: 1,
      text: this.$t('common.select.private'),
      value: 0
    },
    {
      id: 2,
      text: this.$t('common.select.public'),
      value: 1
    }
  ]

  created() {
    this.getYears()
    this.getDays()
    this.getMonths()
    this.getUserPersonalInfo()
  }

  postcodeSearch() {
    UserService.getZipCode(this.personalInfo.zipcode)
      .then(res => {
        if (res.status === 200) {
          this.personalInfo.prefecture = res.data.province_name
          this.personalInfo.city = res.data.district_name
          this.personalInfo.other_address = res.data.city_name
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  getUserPersonalInfo() {
    UserService.getUserPersonalInfo()
      .then(res => {
        if (res.status === 200) {
          if (!_.isEmpty(res.data)) {
            UserModule.HANDLE_USER_PERSONAL_INFO(res.data)
          }

          this.personalInfo = {
            ...store.state.userInfo.user.personal_info,
            phone: [...store.state.userInfo.user.personal_info.phone],
            url: [...store.state.userInfo.user.personal_info.url]
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.$emit('handleUserPersonalInfo', this.personalInfo))
  }

  updatePersonalInfo() {
    if (
      this.personalInfo.birthday_year !== null &&
      this.personalInfo.birthday_month !== null &&
      this.personalInfo.birthday_day !== null
    ) {
      let birthday =
        this.personalInfo.birthday_year +
        '/' +
        this.personalInfo.birthday_month +
        '/' +
        this.personalInfo.birthday_day
      this.checkBirthDate = moment(birthday, 'YYYY/MM/DD').isValid()
    }
    if (
      this.personalInfo.birthday_year === null &&
      this.personalInfo.birthday_month === null &&
      this.personalInfo.birthday_day === null
    ) {
      this.checkBirthDate = true
    }
    this.$refs.observer.validate().then(isValidate => {
      if (!isValidate) {
        return
      } else if (this.checkBirthDate === false) {
        this.errorBirthdayMsg = true
        return
      } else {
        const new_personal_info = {
          gender: this.personalInfo.gender,
          birthday_year: this.personalInfo.birthday_year,
          birthday_month: this.personalInfo.birthday_month,
          birthday_day: this.personalInfo.birthday_day,
          birthday_option: this.personalInfo.birthday_option,
          nearest_station: this.personalInfo.nearest_station,
          zipcode: this.personalInfo.zipcode,
          prefecture: this.personalInfo.prefecture,
          prefecture_option: this.personalInfo.prefecture_option,
          city: this.personalInfo.city,
          other_address: this.personalInfo.other_address,
          building_name: this.personalInfo.building_name,
          phone: this.personalInfo.phone,
          url: this.personalInfo.url
        }
        UserService.updateUserPersonalInfo(new_personal_info)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              res.data.phone = JSON.parse(res.data.phone)
              res.data.url = JSON.parse(res.data.url)
              UserModule.HANDLE_USER_PERSONAL_INFO(res.data)
              this.$emit('isPersonalInfoUpdated', true)
              this.$emit('handleUserPersonalInfo', this.personalInfo)
            }
          })
          .catch(error => {
            this.getUserPersonalInfo()
            this.$emit('isPersonalInfoUpdated', false)
          })
        this.checkBirthDate = false
      }
    })
  }

  addPhoneNumberField() {
    if (this.personalInfo.phone.length < 10) {
      this.personalInfo.phone.push('')
    } else {
      return null
    }
  }

  addUrlField() {
    if (this.personalInfo.url.length < 10) {
      this.personalInfo.url.push('')
    } else {
      return null
    }
  }

  removePhoneNumberField(index: any) {
    this.personalInfo.phone.splice(index, 1)
  }

  removeUrlField(index: any) {
    this.personalInfo.url.splice(index, 1)
  }

  getDays() {
    for (let i = 0; i < 31; i++) {
      this.days.push({
        id: i + 1,
        value: i + 1,
        text: i + 1
      })
    }
    let default_option = {
      id: -1,
      value: null,
      text: this.$t('common.select.not_set')
    }
    this.days.unshift(default_option)
  }

  getMonths() {
    for (let i = 0; i < 12; i++) {
      this.months.push({
        id: i + 1,
        value: i + 1,
        text: i + 1
      })
    }
    let default_option = {
      id: -1,
      value: null,
      text: this.$t('common.select.not_set')
    }
    this.months.unshift(default_option)
  }

  getYears() {
    const currentYear = new Date().getFullYear()
    for (let i = 1945; i <= currentYear; i++) {
      this.years.push({
        id: i,
        value: i,
        text: i
      })
    }
    let default_option = {
      id: -1,
      value: null,
      text: this.$t('common.select.not_set')
    }
    this.years.unshift(default_option)
  }
}
